.help {
    width: 40px;
    margin: 0 auto;
}

.help .question {
    // Fragezeichen symbol
    height: 35px;
    width: 35px;
    background: #ccc;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
}

.help .popup,
.help .popup2 {
    width: 560px;
    height: 0px;
    text-align: left;
    overflow: hidden;
    position: relative;
    background: #eee;
    opacity: 0;
    transition: 1s;
}

.help .popup {
    left: -260px;
    top: 10px;
}

.help .popup2 {
    height: 220px;
}

.help .popup2 h4 {
    font-size: 18px;
    padding: 10px;
    margin: 0;
}
// BOX: original High 270 px
.help:hover .popup {
    opacity: 1;
    height: 400px; // Tiefe des Anzeigefeldes
}

.help .tell-me p:first-child {
    color: #104f94;
    cursor: pointer;
}

.tell-me {
    width: 150px;
}

.help .tell-me:hover .popup2 {
    top: -220px;
    opacity: 1;
}

.help .popup h3 {
    margin: 0;
    padding: 10px 0 0 10px;
    height: 35px;
    background: #ffcc0073;
    color: rgb(0, 0, 0);
    font-weight: 400;
    font-size: 18px;
}

.help .popup p {
    font-size: 16px;
    padding: 10px;
    margin: 0;
}

.help .popup .popup2 .sub-levels {
    padding: 0 0 10px 140px;
}

.help .popup .popup2 .sub-levels strong {
    font-size: 20px;
}

.help .popup .popup2 p:nth-child(5) {
    padding: 20px 0 0 10px;
}

.help .popup a {
    text-decoration: none;
    color: #666666;
}

.help .popup a:visited {
    color: #104f94;
}

.help .popup p em {
    font-size: 12px;
}
